module services {
    export module tariffbook {
        export class tariffBookService implements interfaces.tariffbook.ITariffBookService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getSections(): ng.resource.IResourceClass<interfaces.tariffbook.ITariffBookSection> {
                return this.$resource<interfaces.tariffbook.ITariffBookSection>(this.ENV.DSP_URL + "HsCode/GetSections", {
                    type: "@type",
                    countryId: "@countryId",
                    simulationDate: "@simulationDate",
                    exclusions: "@exclusions"
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            getChaptersBySection(): ng.resource.IResourceClass<interfaces.tariffbook.ITarrifBookChapter> {
                return this.$resource<interfaces.tariffbook.ITarrifBookChapter>(this.ENV.DSP_URL + "HsCode/GetChaptersBySection", {
                    sectionNumber: "@sectionNumber",
                    type: "@type",
                    countryId: "@countryId",
                    simulationDate: "@simulationDate",
                    exclusions: "@exclusions"
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }


            getTariffBookNotes(): ng.resource.IResourceClass<interfaces.tariffbook.ITarrifBookNotes> {
                return this.$resource<interfaces.tariffbook.ITarrifBookNotes>(this.ENV.DSP_URL + "HsCode/GetTariffBookNotes", {
                    type: "@type",
                    countryId: "@countryId",
                    simulationDate: "@simulationDate",
                    code: "@code",
                    isSection: "@isSection"
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }



        }
    }
    angular.module("app").service("tariffBookService", services.tariffbook.tariffBookService);
}